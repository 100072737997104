<template>
  <div id="combo-details-page">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vs-popup classContent="popup-example" title="Discount" :active.sync="showModal">
      <div class="grid grid-cols-2 gap-4">
        <v-select class="col-span-2" v-validate="'required'" :options="depTypes" :label="`name_${$i18n.locale}`" name="depType" :placeholder="$t('discount.selectDep')" v-model="discount.dep_type" :reduce="opt => opt.value" />
        <span class="text-danger text-sm" v-show="errors.has('depType')">{{ errors.first('depType') }}</span>
        
        <flat-pickr v-validate="'required'" v-model="discount.from" :config="configdateTimePicker" class="w-full inputx mt-3" name="from" :placeholder="$t('discount.selectStartDate')" />
        <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
        <flat-pickr v-validate="'required'" v-model="discount.to" :config="configdateTimePicker" class="w-full inputx mt-3" name="to" :placeholder="$t('discount.selectEndDate')" />
        <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
        
        <v-select class="mt-3" v-validate="'required'" :options="types" :label="`name_${$i18n.locale}`" name="type" :placeholder="$t('discount.type')" v-model="discount.type" :reduce="opt => opt.value" />
        <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
        <vs-input v-validate="'required'" v-if="discount.type !== 'bonused'" class="w-full inputx mt-3 mb-1" name="amount" :placeholder="$t('discount.amount')" v-model="discount.amount" />
        <div v-else class="vx-row px-4">
          <vs-input v-validate="'required'" class="w-1/2 inputx pr-2 mb-1 mt-3" name="required" :placeholder="$t('discount.required')" v-model="discount.required" />
          <vs-input v-validate="'required'" class="w-1/2 inputx pl-2 mb-1 mt-3" name="bonus" :placeholder="$t('discount.bonus')" v-model="discount.bonus" />
        </div>
        <span class="text-danger text-sm" v-show="errors.has('required')">{{ errors.first('required') }}</span>
        <span class="text-danger text-sm" v-show="errors.has('bonus')">{{ errors.first('bonus') }}</span>
        <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
        <!-- <vs-input class="w-full inputx mt-3" placeholder="Disabled" v-model="value2" /> -->
        <!-- <vs-textarea class="col-span-2" placeholder="Bellik"></vs-textarea> -->
      </div>
      <div class="flex mt-5">
        <!-- <template v-if="editingDiscount">
          <vs-button @click="deleteDiscount(discount.uuid)" color="danger" class="mt-5" type="border">Delete</vs-button>
          <vs-button @click="updateDiscount(discount.uuid)" color="primary" class="mt-5 ml-auto" type="filled">Update</vs-button>
        </template> -->
        <vs-button @click="addDiscount(combo.id)" color="primary" class="mt-5 ml-auto" type="filled">Submit</vs-button>
      </div>

      <!-- <vs-popup title="Inner popup" :active.sync="popupActive3">
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      </vs-popup> -->
    </vs-popup>
    <vs-alert color="danger" class="mb-4" :active.sync="isPassive">
      <span>{{ $t('comboProduct') }} {{ $t('isNotActive') }}.</span>
    </vs-alert>
    <vx-card v-if="!isLoading" class="mb-6">
      <vs-row class="mb-3">
        <h3 class="mr-auto">{{ combo[`name_${$i18n.locale}`] }}</h3>
        <template v-if="$acl.check('admin')">
          <input type="file" class="hidden" ref="uploadSliderImg" @change="uploadImage($event)" accept="image/*">
          <div class="btn-delete p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="$refs.uploadSliderImg.click()">
            <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base">{{ $t('add_image') }}</span>
          </div>
          <template v-if="combo.isActive">
            <div v-if="!combo.discount" class="btn-delete p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="showModal = true">
              <feather-icon icon="PercentIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base">{{ $t('discount.add') }}</span>
            </div>
          </template>
          <div v-if="combo.discount" class="btn-delete p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteDiscount()">
            <feather-icon icon="PercentIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base">{{ $t('discount.delete') }}</span>
          </div>
          <router-link :to="{name: 'comboEdit', params: {id: $route.params.id}}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t('edit') }}</span>
          </router-link>
          <div v-if="!combo.isActive" class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteData(combo.uuid)">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base">{{ $t('delete') }}</span>
          </div>
        </template>
      </vs-row>
      <vs-table stripe :data="Object.keys(combo)">
        <template slot="thead">
          <vs-th>{{$t('property')}}</vs-th>
          <vs-th>{{$t('value')}}</vs-th>
        </template>
        <template>
          <vs-tr class="w-full">
            <vs-td class="">
              <span class="uppercase font-medium">{{ $t('props.images') }}</span>
            </vs-td>
            <vs-td class="">
              <div class="flex flex-row overflow-x-auto" style="width: 100%;">
                <div v-for="img in combo.images" :key="img.id" class="img-container mr-3 relative p-4">
                  <img :src="`${$baseUrl}/collections/preview/${img.uuid}.webp`" alt="" class="h-40">
                  <vs-button v-if="combo.images.length > 1 && $acl.check('admin')" color="danger" class="px-3 py-2 absolute top-0 right-0" @click="removeImg(img.uuid)">
                    <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
                  </vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
          <vs-tr v-for="(prop, idx) in Object.keys(combo)" :key="idx">
            <vs-td v-if="prop !== 'images'">
              <span class="uppercase font-medium">{{$t(`props.${prop}`)}}</span>
            </vs-td>
            <vs-td v-if="prop === 'image'">
              <div class="img flex flex-row">
                <div class="img-container mr-3">
                  <img src="https://picsum.photos/140.webp" alt="">
                </div>
              </div>
            </vs-td>
            <vs-td v-else-if="combo[prop] === true || combo[prop] === false">
              <vs-chip :color="combo[prop] ? 'success' : 'danger'">
                <feather-icon v-if="combo[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td v-else-if="prop === 'categories'">
              <router-link v-for="item in combo[prop]" :key="item.id" :to="`/categories/${item.uuid}`" class="font-medium">{{ item[`name_${$i18n.locale}`] }}, </router-link>
            </vs-td>
            <vs-td v-else-if="prop === 'collection_products'">
              <router-link v-for="item in combo[prop]" :key="item.id" :to="`/products/${item.uuid}`" class="font-medium">{{ item[`name_${$i18n.locale}`] }}, </router-link>
            </vs-td>
            <vs-td v-else-if="prop === 'discount' && combo.discount">
              <p class="font-medium">
                <vs-chip color="danger">
                  {{ combo.discount.dep_type | capitalize }} {{$t('discount.d')}}
                </vs-chip>
                <vs-chip color="danger mx-3">
                  <span v-if="combo.discount.type === 'bonused'">{{ combo.discount.required }} + {{ combo.discount.bonus }}</span>
                  <span v-else-if="combo.discount.type === 'percentage'">-{{ combo.discount.amount }}%</span>
                  <span v-else>-{{ combo.discount.amount }}TMT</span>
                </vs-chip>
              </p>
              <div class="font-medium">
                {{ new Date(combo.discount.from).toLocaleString('ru-RU') }} - {{ new Date(combo.discount.to).toLocaleString('ru-RU') }}
              </div>
            </vs-td>
            <vs-td v-else-if="prop === 'likes'">
              <span v-if="combo[prop]">{{ combo[prop].length }}</span>
              <span v-else>0</span>
            </vs-td>
            <vs-td v-else-if="prop === 'createdAt' || prop === 'updatedAt' || prop === 'from' || prop === 'to'">
              {{ new Date(combo[prop]).toLocaleString('ru-RU') }}
            </vs-td>
            <vs-td v-else-if="prop !== 'images'">
              {{ combo[prop] }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
export default {
  components: {
    flatPickr: () => import('vue-flatpickr-component'),
    vSelect: () => import('vue-select')
  },
  data () {
    return {
      configdateTimePicker: { enableTime: true, time_24hr: true, locale: Russian },
      combo: { uuid: '', collection_products: [], isActive: true },
      discount: { from: null, to: null },
      types: [
        {value: 'percentage', name_en: 'Percentage', name_tm: 'Protsent', name_ru: 'Процент'},
        {value: 'price', name_en: 'Fix Price', name_tm: 'Kesgitli baha', name_ru: 'Стабильная стоимость'},
        {value: 'bonused', name_en: 'Bonus', name_tm: 'Bonus', name_ru: 'Бонусы'}
      ],
      mainDepTypes: [
        {value: 'both', name_en: 'Both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {value: 'market', name_en: 'Market', name_tm: 'Market', name_ru: 'Маркет'},
        {value: 'express', name_en: 'Express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ],
      newImg: null,
      showModal: false,
      editingDiscount: false,
      isLoading: true
    }
  },
  computed: {
    isPassive () { return !this.combo.isActive },
    depTypes () { return this.combo.type === 'both' ? this.mainDepTypes : this.mainDepTypes.filter((el) => el.value === this.combo.type) }
  },
  async created () { await this.fetchData() },
  methods: {
    async fetchData () {
      await this.$http.get(`/collections/${this.$route.params.id}`).then(response => {
        this.combo = response.data
        this.isLoading = false
      }).catch(err => {
        this.notify('error', this.$t('notify.error'), err.message)
      })
      this.isLoading = false
    },
    uploadImage (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.newImg = e.target.result
          this.$http.post(`/collections/upload-image/${this.combo.uuid}`, { photo: this.newImg}).then(() => {
            this.notify('success', this.$t('notify.success'), `${this.$t('image')} ${this.$t('notify.sUploaded')}`)
            this.fetchData()
          }).catch(err => {
            this.notify('error', this.$t('notify.error'), err.message)
            this.newImg = null
          })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    async removeImg (id) {
      await this.$http.delete(`/collections/delete-image/${id}`).then(() => {
        this.notify('success', this.$t('notify.success'), `${this.$t('image')} ${this.$t('notify.sDeleted')}`)
        this.fetchData()
      }).catch(err => {
        this.notify('error', this.$t('notify.error'), err.message)
      })
    },
    addDiscount (id) {
      
      this.$validator.validateAll().then(result => {
        if (result) {
          this.discount.from = new Date(this.discount.from)
          this.discount.to = new Date(this.discount.to)
          this.$http.post('/collections/discount/add', {
            ...this.discount,
            collectionId: id
          }).then(response => {
            if (response.status === 201) {
              this.notify('success', this.$t('notify.success'), `${this.$t('discount.d')} ${this.$t('notify.sCreated')}`)
              this.showModal = false
              this.fetchData()
            }
          }).catch(err => { this.notify('Error', err.message) })
        } else {
          this.notify('error', this.$t('notify.error'), 'Form is not valid!')
        }
      })
    },
    // editDiscount () {
    //   this.discount = this.combo.discount
    //   this.editingDiscount = true
    //   this.showModal = true
    // },
    // updateDiscount (uuid) {
    //   this.$http.patch(`/collections/discount/edit/${uuid}`).then(response => {
    //     if (response.status === 204) {
    //       this.notify('Success', 'Discount successfully deleted!')
    //       this.showModal = false
    //       this.fetchData()
    //     }
    //   }).catch(err => { this.notify('Error', err.message) })
    // },
    deleteDiscount () {
      this.$http.delete(`/collections/discount/delete/${this.combo.discount.uuid}`).then(response => {
        if (response.status === 200) {
          this.notify('success', this.$t('notify.success'), `${this.$t('discount.d')} ${this.$t('notify.sDeleted')}`)
          this.showModal = false
          this.fetchData()
        }
      }).catch(err => {
        this.notify('error', this.$t('notify.error'), err.message)
      })
    },
    deleteData (id) {
      this.$http.delete(`/collections/delete/${id}`).then(() => {
        this.notify(this.$t('notify.success'), `${this.$t('comboProduct')} ${this.$t('notify.sDeleted')}`)
        this.$router.push({ name: 'combos' })
      }).catch(err => { this.notify('error', this.$t('notify.error'), err.message) })
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>